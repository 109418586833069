import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components/macro';

/**
 * ErrorMessage component.
 * This is for Form errors to display at the top of the form. (Not for individual field errors)
 */
const ErrorMessage: React.FC = ({ children }) => (
  <StyledError>
    <FontAwesomeIcon icon={faExclamationTriangle} transform="grow-14" />
    {children}
  </StyledError>
);

const StyledError = styled.div`
  align-items: center;
  color: ${p => p.theme.color.error};
  display: flex;
  font-weight: bold;
  margin: 0 0 35px 0;

  svg {
    margin: 15px;
  }
`;

export { ErrorMessage };
