import React from 'react';
import { useField, FieldConfig } from 'formik';
import styled from 'styled-components/macro';

import { Field } from './Field';

type Props = FieldConfig & {
  id?: string;
  label: string;
  rows?: number;
};

const TextArea = (props: Props) => {
  const { id, name, rows, label } = props;
  const [ field, meta ] = useField<string>(name);

  const error = meta.touched && meta.error ? meta.error : ``;

  const textAreaProps = React.useMemo(
    () => ({
      id: id || name,
      rows,
      error,
    }),
    [ id, name, rows, error ]
  );

  return (
    <Field name={name} label={label} floatLabel={!!field.value} error={error}>
      <StyledTextArea {...field} {...textAreaProps} />
    </Field>
  );
};

const StyledTextArea = styled.textarea<{ error?: string }>`
  background: #fff;
  border: 1px solid ${p => p.error ? `red` : `orange` };
  color: orange;
  font-size: 1rem;
  padding: 8px;
  outline: none;
  width: 100%;
`;

export { TextArea };
