import { commonProperties, commonColors } from './shared';
import * as ThemeModels from './types';

const gray = commonColors.white;
const primary = commonColors.orange;
const secondary = commonColors.white;
const tertiary = commonColors.green;

const adminNav: ThemeModels.AdminNav = {
  color: {
    mainBackground: primary[500],
    brandBackground: primary[700],
    link: {
      text: gray[200],
      border: primary[600],
      iconMain: `#000`,
      iconSecondary: gray[900],
      active: {
        text: primary[500],
        background: gray[800],
        iconMain: primary[500],
        iconSecondary: primary[500],
      },
    },
  },
};

export const lightTheme: ThemeModels.Theme = {
  ...commonProperties,
  color: {
    background: {
      primary: gray[100],
      secondary: gray[200],
      tertiary: gray[300],
    },
    foreground: {
      primary: gray[900],
      secondary: gray[800],
      tertiary: gray[700],
    },
    primary,
    secondary,
    tertiary,
    success: tertiary[800],
    error: primary[400],
    button: {
      none: {
        color: gray[600],
        disabledColor: gray[500],
        textColor: gray[100],
      },
      primary: {
        color: commonColors.blue[500],
        disabledColor: commonColors.blue[300],
        textColor: gray[100],
      },
      success: {
        color: commonColors.green[700],
        disabledColor: commonColors.green[900],
        textColor: gray[100],
      },
      danger: {
        color: commonColors.red[500],
        disabledColor: commonColors.red[600],
        textColor: gray[100],
      },
    },
    gray,
  },
  adminNav,
};
