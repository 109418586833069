import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import styled from 'styled-components/macro';
import 'typeface-raleway';

import { Nav } from './nav/Nav';
import { Home } from './pages/Home';
import { Admin } from './pages/Admin';
import { Contact } from './pages/Contact';

const Recipes = React.lazy(() => import(`./pages/Recipes`));

const App = () =>
  (
    <Router>
      <Body>
        <Nav />
        <Content>
          <React.Suspense fallback=" ">
            <Switch>
              <Route exact={true} path="/home" component={Home} />
              <Route path="/recipes" component={Recipes} />
              <Route path="/contact" component={Contact} />
              <Route path="/admin" component={Admin} />
              <Redirect to="/home" />
            </Switch>
          </React.Suspense>
        </Content>
      </Body>
    </Router>
  );

const Content = styled.div`
  margin-top: 100px; /* to make room for the fixed header */
  margin-left: 0;
  padding: 1rem 0.6rem;
  width: 100%;
  z-index: 1;
`;

const Body = styled.div`
  font-family: Raleway, sans-serif;
`;

export { App };
