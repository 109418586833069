import { commonProperties, commonColors } from './shared';
import * as ThemeModels from './types';

const gray = commonColors.gray;
const primary = commonColors.red;
const secondary = commonColors.blue;
const tertiary = commonColors.green;

const adminNav: ThemeModels.AdminNav = {
  color: {
    mainBackground: primary[900],
    brandBackground: primary[700],
    link: {
      text: primary[100],
      border: primary[800],
      iconMain: primary[500],
      iconSecondary: primary[500],
      active: {
        text: gray[900],
        background: primary[500],
        iconMain: `#000`,
        iconSecondary: gray[900],
      },
    },
  },
};

export const darkTheme: ThemeModels.Theme = {
  ...commonProperties,
  color: {
    background: {
      primary: gray[900],
      secondary: gray[800],
      tertiary: gray[700],
    },
    foreground: {
      primary: gray[100],
      secondary: gray[200],
      tertiary: gray[300],
    },
    primary,
    secondary,
    tertiary,
    success: tertiary[800],
    error: primary[400],
    button: {
      none: {
        color: gray[600],
        disabledColor: gray[300],
        textColor: gray[100],
      },
      primary: {
        color: commonColors.blue[500],
        disabledColor: commonColors.blue[300],
        textColor: gray[100],
      },
      success: {
        color: commonColors.green[700],
        disabledColor: commonColors.green[500],
        textColor: gray[100],
      },
      danger: {
        color: commonColors.red[500],
        disabledColor: commonColors.red[300],
        textColor: gray[100],
      },
    },
    gray,
  },
  adminNav,
};
