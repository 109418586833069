import { createGlobalStyle } from 'styled-components';

/**
 * Global Styles.
 *
 * Do not add styles here unless they are truly global styles.
 * Meaning: specificity won't be an issue and they should always take effect.
 */
export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-family: Raleway, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    background: ${p => p.theme.color.background.secondary};
    color: ${p => p.theme.color.foreground.primary};
    display: flex;
    flex-direction: column;
  }

  html,
  body {
    width: 100%;
    height: 100%;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  #root {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  div[role=group] {
    flex: 1;
  }

/*
  a,
  a:visited,
  a:hover {
    color: orange;
    text-decoration: none;
  }
*/

`;
