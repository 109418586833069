import React from 'react';
import styled from 'styled-components/macro';
import { DefaultTheme, ThemedStyledProps } from 'styled-components';

/* The props for the main `Button` component and for other button components. */
export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  /* Controls the color(s) used on the button to signify the intent of the button. */
  intent?: `none` | `primary` | `success` | `danger`;

  /* Controls the non-color related styles for different button variants */
  variant?: `contained` | `outlined`;
};

const defaultProps: ButtonProps = {
  intent: `none`,
  variant: `contained`,
};

/**
 * Button component.
 * Most of this component is in the styling which is based on the incoming props.
 */
const Button = (props: ButtonProps) => (
  <StyledButton type="button" {...props} />
);

Button.defaultProps = defaultProps;

/**
 * The props output by `getAttrProps` after applying some logic to the `IButtonProps`.
 * These are used in the actual css properties.
 */
type StyledButtonProps = {
  bgColor: string;
  borderColor: string;
  borderWidth: string;
  textColor: string;
};

/**
 * Take in the main `Button` props and transform them into new props that are used in
 * the styled-component `StyledButton`.
 * @param props The incoming props from the main `Button` component.
 */
const getAttrProps = (props: ThemedStyledProps<ButtonProps, DefaultTheme>) => {
  const intent = props.intent /* istanbul ignore next */ || `none`; /* The || will never be hit */
  const button = props.theme.color.button[intent];

  /* The defaults */
  const attrProps: StyledButtonProps = {
    bgColor: button.color,
    borderColor: props.disabled ? button.disabledColor : button.color,
    borderWidth: props.variant === `outlined` ? `1px` : `0px`,
    textColor: button.textColor,
  };

  /* Alter the defaults, if needed */
  if (props.variant === `outlined`) {
    attrProps.bgColor = `transparent`;

    if (props.disabled) {
      attrProps.textColor = button.disabledColor;
    }
    else {
      attrProps.textColor = button.color;
    }
  }
  else if (props.disabled) {
    attrProps.bgColor = button.disabledColor;
  }

  return attrProps;
};

const StyledButton = styled.button.attrs<ButtonProps, StyledButtonProps>(getAttrProps)`
  align-self: center;
  background-color: #fff;
  border: 1 solid orange;
  color: orange;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -1px;
  margin: 1rem 0.3rem;
  outline: none;
  padding: 8px 12px;
  width: auto;

  :disabled {
    cursor: auto;
    filter: brightness(60%);
  }
`;

export { Button };
