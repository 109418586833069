import React from 'react';
import { useFormik, FormikProvider, FormikConfig } from 'formik';
import styled from 'styled-components/macro';

import { ErrorMessage } from './ErrorMessage';

export type FormState = {
  blockSubmit?: boolean;
};

/* All the valid actions for dispatch to the `Form` state reducer. */
export type FormActionType =
  | { type: `BLOCK_SUBMIT` }
  | { type: `UNBLOCK_SUBMIT` };

export type FormContext = {
  /* Map of the raw errors to user friendly English messages, which then get translated */
  errorMap: Record<string, string>;

  /* Reducer dispatch for state changes */
  dispatch: React.Dispatch<FormActionType>;
};

/* The fields from the context that can be passed in as props */
type FormPropFields = `errorMap`;

export type FormProps<Values> = FormikConfig<Values> & Pick<FormContext, FormPropFields>;

const reducer = (state: FormState, action: FormActionType) => {
  switch (action.type) {
    case `BLOCK_SUBMIT`:
      return {
        ...state,
        blockSubmit: true,
      };
    case `UNBLOCK_SUBMIT`:
      return {
        ...state,
        blockSubmit: false,
      };
   /* no default */
  }
};
const Context = React.createContext({} as FormContext);

/**
 * Form component using Formik to do the heavy lifting.
 */
const Form = <Values extends {}>(props: FormProps<Values>) => {
  const { errorMap, children, ...formikConfig } = props;
  const [ state, dispatch ] = React.useReducer(reducer, { blockSubmit: false });
  const formik = useFormik(formikConfig);

  const formContext: FormContext = React.useMemo(
    () => ({ errorMap, dispatch }),
    [errorMap]
  );

  /**
   * Form-wide errors shown at the top of the form.
   * Try to get the user friendly error from the errorMap.
   * If it's not in the map, just return the raw message.
   */
  const errorId: string = React.useMemo(
    () => {
      if (formik.status) {
        return formik.status;
      }

      return undefined;
    },
    [formik.status]
  );

  const onSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      if (state.blockSubmit) {
        e.preventDefault();
      }
      else {
        formik.handleSubmit(e);
      }
    },
    [ formik, state.blockSubmit ]
  );

  return (
    <FormikProvider value={formik}>
      <Context.Provider value={formContext}>
        {errorId && (
          <ErrorMessage>
            {errorId}
          </ErrorMessage>
        )}
        <StyledForm noValidate={true} onSubmit={onSubmit} data-testid="form">
          {children}
        </StyledForm>
      </Context.Provider>
    </FormikProvider>
  );
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const useFormContext = () => React.useContext(Context);

export { Form };
