import React from 'react';
import { useField, FieldConfig } from 'formik';
import styled from 'styled-components/macro';

import { Field } from './Field';

type ValidInputTypes = `text` | `email` | `password`;

type Props = FieldConfig & {
  id?: string;
  label: string;
  type: ValidInputTypes;
  autoFocus?: boolean;
};

const Input = (props: Props) => {
  const { id, name, label, type, autoFocus } = props;
  const [ field, meta ] = useField<string>(name);

  const error = meta.touched && meta.error ? meta.error : ``;

  const inputProps = React.useMemo(
    () => ({
      id: id || name,
      type,
      autoFocus,
      error,
    }),
    [ autoFocus, error, id, name, type ]
  );

  return (
    <Field name={name} label={label} floatLabel={!!field.value} error={error}>
      <StyledInput {...field} {...inputProps} />
    </Field>
  );
};

const StyledInput = styled.input<{ error?: string }>`
  background: ${p => p.theme.color.background.primary};
  border: 1px solid orange;
  color: orange;
  font-size: 1rem;
  padding: 8px;
  outline: none;
  width: 100%;
`;

export { Input };
