import React from 'react';
import styled from 'styled-components/macro';
import { NavLinks } from './NavLinks';
import { Brand } from './Brand';

/**
 * Main Navigation for Admin.
 */

const onNavItemClick = () => {
  console.log(`clicked`);
};

const Nav = () => (
  <>
    <Brand />
    <StyledNav>
      <Content>
        <NavLinks onNavItemClick={onNavItemClick} />
      </Content>
    </StyledNav>
  </>
);

const StyledNav = styled.nav`
  top: 80px;
  left: 0;
  overflow: hidden;
  position: fixed;
  transition: all 250ms;
  width: 100%;
  background-color: white;
  z-index: 254;
`;

const Content = styled.div`
  display: flex;
  overflow: hidden;
  transition: all 250ms;
  width: 100%;
  align-content: center;
`;

export { Nav };
