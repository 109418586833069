import React from 'react';
import styled from 'styled-components/macro';

/**
 * A wrapper around a form's buttons to provide styling and layout.
 */
const ButtonGroup: React.FC = ({ children }) => (
  <StyledButtonGroup>
    {children}
  </StyledButtonGroup>
);

const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: center;

  button {
    flex-grow: 1;
    max-width: 12rem;
  }
`;

export { ButtonGroup };
