import React from 'react';
import styled from 'styled-components/macro';

// import styled from 'styled-components/macro';
const content1 = `coming soon...`;

const Admin = () => (
  <Wrapper>
    <H1>Admin</H1>
    <Content>
      {content1}
    </Content>
  </Wrapper>
);

const Content = styled.div`
  width: 100%;
  font-family: Raleway, sans-serif;
  display: flex-item;
  padding: 10px;

  @media (min-width: 40em) {
    width: 65%;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const H1 = styled.h1`
  align-items: center;
  display: flex-item;
  flex-shrink: 0;
  font-size: 1.2rem;
  justify-content: center;
  transition: all 250ms;
  -webkit-tap-highlight-color: transparent;
  padding-right: 4%;
  color: orange;

  @media (min-width: 40em) {
    font-size: 2rem;
  }
`;

export { Admin };
