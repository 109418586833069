import React from 'react';
import styled from 'styled-components/macro';

import { useFormContext } from './Form';

type Props = {
  error: string;
};

/**
 * Component to show a styled error for an individual field using the current form's errorMap.
 */
const FieldError = (props: Props) => {
  const formContext = useFormContext();

  /* Get the user friendly error from the errorMap */
  const error = formContext.errorMap[props.error];

  /* If no mapping exists, default to the raw error */
  const errorId = error ? props.error : ``;

  return (
    <Wrapper>
      {errorId}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  color: ${p => p.theme.color.error};
  font-size: 0.8rem;
  padding: 5px 0 0 5px;
`;

export { FieldError };
