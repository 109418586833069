import React from 'react';

import { Button, ButtonProps } from './Button';

/**
 * Submit button component.
 * Currently, just a Button of type="submit".  Could expand to include more functionally later.
 */
const SubmitButton = (props: ButtonProps) => (
  <Button {...props} type="submit" />
);

export { SubmitButton };
