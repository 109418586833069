import * as ThemeModels from './types';

import { lightTheme } from './lightTheme';
import { darkTheme } from './darkTheme';

const themes: Record<string, ThemeModels.Theme> = {
  dark: darkTheme,
  light: lightTheme,
};

export { themes };
