import { useContext } from 'react';
import { __RouterContext, RouteComponentProps } from 'react-router-dom';

/**
 * React Router will have it's own hooks eventually.  This one is temporary until then.
 *
 * The `Params` generic is used to define the URL params captured in the route, if any.
 */

const useRouter = <Params = {}>() => useContext(__RouterContext) as RouteComponentProps<Params>;

export { useRouter };
