import * as ThemeModels from './types';

export const commonProperties: ThemeModels.Common = {
  layerIndex: {
    modal: 100000,
    nav: 99995,
    overlay: 99990,
  },
};

export const commonColors: Record<string, ThemeModels.Color> = {
  gray: {
    100: `#eee`,
    200: `#cfcfcf`,
    300: `#bbb`,
    400: `#9f9f9f`,
    500: `#888`,
    600: `#6f6f6f`,
    700: `#555`,
    800: `#3f3f3f`,
    900: `#222`,
  },
  red: {
    100: `#ffbbac`,
    200: `#ff9279`,
    300: `#ff6846`,
    400: `#ff3e13`,
    500: `#df2900`,
    600: `#ac2000`,
    700: `#770c00`,
    800: `#681300`,
    900: `#4b0000`,
  },
  blue: {
    100: `#b2d0ff`,
    200: `#96bfff`,
    300: `#7badfd`,
    400: `#438aff`,
    500: `#1767e5`,
    600: `#145bcc`,
    700: `#124fb3`,
    800: `#0d4193`,
    900: `#09306f`,
  },
  green: {
    100: `#d0ffb3`,
    200: `#bfff96`,
    300: `#adfc7c`,
    400: `#8bff42`,
    500: `#66e517`,
    600: `#5bcc14`,
    700: `#4fb312`,
    800: `#41940d`,
    900: `#317009`,
  },
  white: {
    100: `#FFF`,
    200: `#FFF`,
    300: `#FFF`,
    400: `#FFF`,
    500: `#FFF`,
    600: `#FFF`,
    700: `#FFF`,
    800: `#333`,
    900: `#000`,
  },
  orange: {
    100: `#FF8C00`,
    200: `#FF8C00`,
    300: `#FF8C00`,
    400: `#FF8C00`,
    500: `#FF8C00`,
    600: `#FF8C00`,
    700: `#FF8C00`,
    800: `#333`,
    900: `#000`,
  },
};
