/**
 * Error Map for errors shared across the Store components.
 */
export const errorMap: Record<string, string> = {
  /* Server response validation messages */
  slugExists: `URL Name already exists`,
  invalidSlug: `URL Name contains invalid characters.`,

  /* Yup validation messages */
  'name.required': `Store name is required`,
  'phone.positive': `A valid phone number is required`,
  'phone.required': `Store phone is required`,
  'email.valid': `A valid email is required`,
  'email.required': `Store email is required`,
  'status.required': `Store status is required`,
  'address.required': `Store address is required`,
  'urlSlug.invalid': `URL Name contains invalid characters.`,
  'urlSlug.required': `URL Name is required`,
};
