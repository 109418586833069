import React from 'react';
import styled from 'styled-components/macro';
import { StyledLink } from '../shared/StyledComps';

const imageFile = `/assets/logo.png`;
const logo = `${process.env.PUBLIC_URL}${imageFile}`;

const Brand = () => (
  <Wrapper>
    <StyledLink to="/">
      <H1>
        <Logo src={logo} alt="logo" />
      Burningfood
      </H1>
    </StyledLink>
  </Wrapper>
);

const Wrapper = styled.div`
  align-items: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: black;
  color: white;
  z-index: 255;
`;

const H1 = styled.h1`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  font-size: 2rem;
  justify-content: center;
  transition: all 250ms;
  -webkit-tap-highlight-color: transparent;
  padding-right: 4%;

  /* cursor: pointer; */
`;

const Logo = styled.img`
  height: 25px;
  width: 25px;
`;

export { Brand };
