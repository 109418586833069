import React from 'react';
import styled from 'styled-components/macro';

// import styled from 'styled-components/macro';

const content1 = `My name is Joe, i'm a web developer and I like to cook. Anyway.... This
combination of things led me to create this website to consolidate my growing list of links
to websites containing nutritional info, recipes, and other interesting bits. I also wanted
to have an easy place for me to find my recipes that i use each week. It also makes it easier
for me to share recipes with others, when all i have to do is tell them to come to this site.
I mostly use my phone or tablet when visiting this site while i'm cooking, using it mostly
as a online recipe book. Also it's helpful when i'm at the store, and i need to remember
what ingredients i need for a particular dish.`;

const content2 = `Feel free to browse any of the recipes and
give them a try. If i find a recipe on the net, i always link it to wherever i got it from
to give credit to the original author (located under the picture i take of the food).
If there is no link provided, then it was a recipe i just made up at home. Also, all the
images i use for the food (besides the pyrex image) are images that i've taken myself of the
food i've prepared. You are free to use any of the food images as long as you credit this site.
The Category pictures are ones i "borrowed" from the net. If you own one of them, and
don't want me using it, please email me and i'll remove the image at once. Also be warned,
that this site will be continuously under construction, as i like to test out new ideas.`;

const Home = () => (
  <Wrapper>
    <H1>Welcome to Burningfood.com</H1>
    <Content>
      {content1}
    </Content>
    <Content>
      {content2}
    </Content>
    <Content>
      Site built in: React.js!
    </Content>
  </Wrapper>
);

const Content = styled.div`
  width: 100%;
  font-family: Raleway, sans-serif;
  display: flex-item;
  padding: 10px;

  @media (min-width: 40em) {
    width: 65%;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const H1 = styled.h1`
  align-items: center;
  display: flex-item;
  flex-shrink: 0;
  font-size: 1.2rem;
  justify-content: center;
  transition: all 250ms;
  -webkit-tap-highlight-color: transparent;
  padding-right: 4%;
  color: orange;

  @media (min-width: 40em) {
    font-size: 2rem;
  }
`;
export { Home };
