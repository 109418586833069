import React from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { useRouter } from '../shared/hooks/useRouter';
import * as Models from '../utils/Models';

import {
  Home,
  Plate,
  Mail,
  Heart,
} from '../assets/icons';

const links: Models.Link[] = [
  {
    text: `Home`,
    to: `home`,
    svg: Home,
  },
  {
    text: `Recipes`,
    to: `recipes`,
    svg: Plate,
  },
  {
    text: `Contact`,
    to: `contact`,
    svg: Mail,
  },
  {
    text: `Admin`,
    to: `admin`,
    svg: Heart,
  },
];

const NavLinks = React.memo((props: Models.NavProps) => {
  let { match: { path } } = useRouter();

  if (path === `/`){
    path = ``;
  }

  return (
    <Wrapper>
      {links.map(item => (
        <Item key={item.to}>
          <SiteNavLink
            to={`${path}/${item.to}`}
            onClick={props.onNavItemClick}
            title={item.text}
          >
            <item.svg width="15" height="15" />
            {` `}
            {item.text}
          </SiteNavLink>
        </Item>
      ))}
    </Wrapper>
  );
});

const Wrapper = styled.ul`
  flex-grow: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  background-color: "#EEEEEE";
`;

const Item = styled.li`
  align-items: center;
  border: 0;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const SiteNavLink = styled(NavLink)`
  align-items: center;
  color: orange;
  display: flex;
  flex-basis: 100%;
  font-size: 0.9rem;
  font-weight: bold;
  margin: 0;
  padding: 10px;
  text-decoration: none;
  white-space: nowrap;

  img {
    margin-right: 0.8rem;
  }

  @media (max-width: 40em) {
    svg {
      display: none;
    }

    img {
      display: none;
    }
  }

  svg {
    --color-1: orange;
    --color-2: white;

    margin-right: 0.8rem;
  }

  &[aria-current] {
    background: orange;
    color: white;
    align-content: center;

    svg {
      --color-1: white;
      --color-2: orange;
    }
  }
`;

export { NavLinks };
