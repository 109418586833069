import React from 'react';
import { ThemeProvider } from 'styled-components/macro';
import { useLocalStorage } from './shared/hooks';
import { themes } from './shared/theme';
import * as Models from './utils/Models';

/**
 * @summary
 * This is the global state for the app.  It should contain any variables or functions needed app
 * wide. It should stay relatively small though.  Only stuff that is truly needed in the entire
 * app should be here.
 */

/**
 * Full App Context.
 */

/**
 * Create the context itself.
 * `createContext` requires a default value, which is not really known yet.
 * To avoid having to create a dummy default object, disable the tslint rule.
 * This will allow an empty object as the default.
 * Just make sure the real context object is created before the first render.
 * @see https://github.com/sw-yx/react-typescript-cheatsheet#context
 */
/* tslint:disable-next-line:no-object-literal-type-assertion */
const AppContext = React.createContext({} as Models.Context);

/**
 * The main Provider that maintains the state and contains the functions that manipulate it.
 */
const AppContextProvider: React.FC = props => {
  const [ themeName, setThemeName ] = useLocalStorage<string>(`themeName`, `light`);

  const context: Models.Context = React.useMemo(
    () => ({
      themeName,
      setThemeName,
    }),
    [ setThemeName, themeName ]
  );

  return (
    <AppContext.Provider value={context}>
      <ThemeProvider theme={themes[themeName]}>
        <div>
          {props.children}
        </div>
      </ThemeProvider>
    </AppContext.Provider>
  );
};

/**
 * Quick access to the App Context.
 */
const useAppContext = () => React.useContext(AppContext);

export {
  AppContextProvider,
  useAppContext,
};
