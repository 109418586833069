import styled from 'styled-components/macro';

/* A normal Label that just sits above the form element */
const Label = styled.label`
  font-size: 75%;
  padding-bottom: 0.3em;
`;

/**
 * A FloatingLabel will animate in and out of the form element based
 * on some property of the element.  Typically, the value.
 * Make sure the parent container is set to `position: relative`.
 * @param float True = float above the element.
 */
const FloatingLabel = styled.label<{ float: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px 0 0 13px;
  transition: all 200ms;
  opacity: ${p => p.float ? 1 : 0.8};
  ${p => p.float && `
    font-size: 75%;
    transform: translate3d(0, -110%, 0);
    padding-left: 3px;
  `}
`;

export { Label, FloatingLabel };
