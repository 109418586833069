import React from 'react';
import styled from 'styled-components/macro';
/* import { SentMessageInfo } from 'nodemailer/lib/smtp-transparent'; */
import { ContactForm } from './ContactForm';

const Contact = () => {
  const [ isMailed, setIsMailed ] = React.useState(false);

  const doSomething = function(e: any) {

    fetch(`http://localhost:4000/postemail`, {
      method: `POST`,
      headers: {
        'Content-Type': `application/json`,
      },
      body: JSON.stringify({
        name: e.name,
        description: e.description,
        email: e.email,
      }),
    }).then(res => res.json())
      .then(response => console.log(`Success:`, JSON.stringify(response)))
      .catch(error => console.error(`Error:`, error));

    setIsMailed(true);
    e.preventDefault();
  };

  const loading = false;

  return (
    <Wrapper>
      <H1>Contact</H1>
      <Content>
        {isMailed ? (
          <H4>Thank you, your mail has been sent!</H4>
        ) : (
          <ContactForm
            onSubmit={doSomething}
            loading={loading}
          />
        )
        }
      </Content>
    </Wrapper>
  );
};

const Content = styled.div`
  width: 100%;
  font-family: Raleway, sans-serif;
  display: flex-item;
  padding: 10px;

  @media (min-width: 40em) {
    width: 50%;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const H1 = styled.h1`
  align-items: center;
  display: flex-item;
  flex-shrink: 0;
  font-size: 1.2rem;
  justify-content: center;
  transition: all 250ms;
  -webkit-tap-highlight-color: transparent;
  padding-right: 4%;
  color: orange;

  @media (min-width: 40em) {
    font-size: 2rem;
  }
`;

const H4 = styled.h4`
  align-items: center;
  display: flex-item;
  flex-shrink: 0;
  justify-content: center;
  transition: all 250ms;
  -webkit-tap-highlight-color: transparent;
  padding-right: 4%;
  color: orange;

  @media (min-width: 40em) {
    font-size: 1rem;
  }
`;
export { Contact };
