import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

/**
 * Style up a Reach Router Link component
 */
const StyledLink = styled(Link)`
  align-self: center;
  color: orange;
  margin-top: 1rem;
  text-decoration: none;
  width: auto;
`;

/**
 * Use the alternate link color.
 * Usually used when being placed on a darker background.
 */
const StyledLinkAlt = styled(StyledLink)`
  color: lightcoral;
`;

const StyledLoading = styled.div`
  font-size: 1.5rem;
  margin: 3rem;
`;

export { StyledLink, StyledLinkAlt, StyledLoading };
