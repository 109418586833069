import React from 'react';
import { FormikHelpers } from 'formik';

import { Form } from '../../shared/form/Form';
import { TextArea } from '../../shared/form/TextArea';
import { SubmitButton } from '../../shared/form/SubmitButton';
import { ButtonGroup } from '../../shared/form/ButtonGroup';
import { Input } from '../../shared/form/Input';

import { validationSchema } from './validation';
import { errorMap } from '../../shared/form/errorMap';

type CreateFormValues = {
  name: string;
  email: string;
  description: string;
  status: boolean;
};

type FormValues = CreateFormValues;

type Props = {
  loading: boolean;
  onSubmit(input: any): void;
};

const getInitialValues = () => ({
  name: ``,
  email: ``,
  status: true,
  description: ``,
});

const ContactForm = (props: Props) => {
  const { onSubmit, loading } = props;
  const initialValues = React.useMemo(() => getInitialValues(), []);

  const submitDisabled = loading ? { disabled: true } : {};

  const onFormSubmit = React.useCallback(
    (values: FormValues, actions: FormikHelpers<FormValues>) => (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      new Promise((_resolve, _reject) => {
        actions.setStatus(undefined);

        const { status, ...rest } = values;
        const input = {
          isActive: status,
          ...rest,
        };

        /* Call back to the parent component so it can run it's mutation with the new input */
        onSubmit(input);
      })
    ),
    [onSubmit]
  );

  return (
    <Form
      initialValues={initialValues}
      validationSchema={validationSchema()}
      onSubmit={onFormSubmit}
      errorMap={errorMap}
    >
      <Input
        type="text"
        name="name"
        label="Name"
      />
      <Input
        type="text"
        name="email"
        label="Email"
      />
      <TextArea
        rows={5}
        name="description"
        label="Description"
      />
      <ButtonGroup>
        <SubmitButton intent="success" {...submitDisabled}>
          Submit
        </SubmitButton>
      </ButtonGroup>
    </Form>
  );
};

export { ContactForm };
