import { css, FlattenSimpleInterpolation, SimpleInterpolation } from 'styled-components';

/**
 * Breakpoint setup.
 *
 * CSS Styles should use a "Mobile First" approach.  Which basically means
 * designing for the smallest screens first.  Then add "min-width" media queries
 * to alter the design for larger screens to make use of the space.
 *
 * To use this:
 *   import { media } from '@recom/ui-theme';
 *
 * Then inside a styled-component, add the media properties:
 *   ${media.medium`
 *     background: blue;
 *   `}
 *
 * The Media template concept was taken from the styled-components tips-and-tricks.
 * Their example uses max-width, but the code setup is basically the same.
 * @see https://github.com/styled-components/styled-components/blob/master/docs/tips-and-tricks.md#media-templates
 */

/**
 * extra-small would be anything less then the small size.  But, we don't need to
 * define that since it will be the default for the mobile first approach.
 */
const breakpoints: Record<string, number> = {
  small: 600,
  medium: 900,
  large: 1200,
  giant: 1800,
};

// eslint-disable-next-line max-len
type IMedia = Record<string, (strings: TemplateStringsArray, ...interpolations: SimpleInterpolation[]) => FlattenSimpleInterpolation>;

const mediaDefault: IMedia = {};

const media: IMedia = Object.keys(breakpoints).reduce(
  (accumulator, label) => {
    const emSize = breakpoints[label] / 16;
    accumulator[label] = (strings, ...interpolations) => css`
      @media (min-width: ${emSize}em) {
        ${css(strings, ...interpolations)};
      }
    `;

    return accumulator;
  },
  mediaDefault
);

export { breakpoints, media };
