import React from 'react';
import styled from 'styled-components/macro';

import { FloatingLabel } from './Label';
import { FieldError } from './FieldError';

type Props = {
  name: string;
  label: string;
  floatLabel: boolean;
  error?: string;
};

/**
 * This Field component centralizes the styling, label, and error for any form components.
 * However, some form components will need more control and may not be able to use this.
 */
const Field: React.FC<Props> = props => (
  <Wrapper>
    <FloatingLabel htmlFor={props.name} float={props.floatLabel}>
      {props.label}
    </FloatingLabel>
    {props.children}
    {props.error && <FieldError error={props.error} />}
  </Wrapper>
);

const Wrapper = styled.div`
  position: relative;
  margin-top: 2rem;
`;

export { Field };
