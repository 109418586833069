import { useState, useEffect } from 'react';

/**
 * An enhanced useState hook that also stores the key/value to localStorage.
 * @param key The key to use in localState.
 * @param defaultValue The default value for the state.
 */

export const useLocalStorage = <T>(key: string, defaultValue: T): [T, (value: T) => void] => {
  const [ state, setState ] = useState<T>(() => {
    let value: T;
    try {
      value = JSON.parse(localStorage.getItem(key) || JSON.stringify(defaultValue));
    }
    catch (e) {
      /* This catch is a fallback in case the JSON.parse
      /* throws an error, which is pretty unlikely */
      /* istanbul ignore next */
      value = defaultValue;
    }

    return value;
  });

  useEffect(
    () => {
      localStorage.setItem(key, JSON.stringify(state));
    },
    [ key, state ]
  );

  return [ state, setState ];
};
