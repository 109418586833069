import { object, string, StringSchema } from 'yup';

type ValidationSchema = {
  name: StringSchema;
  email: StringSchema;
};

export const validationSchema = () => {
  const schema: ValidationSchema = {
    name: string().required(`name.required`),
    email: string()
      .email(`email.valid`)
      .required(`email.required`),
  };

  return object().shape(schema);
};
